@import "../../../styles/description.scss";

.wrapper {
  height: 48px;
  color: #fff;
  background-color: $color-red;
  display: flex;
  border-radius: 30px;
  transition: all 0.3s ease;
  outline: none;
  cursor: pointer;

  &:hover {
    background-color: $color-red-hover;
  }

  &:active {
    background-color: $color-red-active;
  }

  @include mobile {
    width: 100%;
  }
}

.button_link__secondary {
  color: $color-red;
  background-color: #fff;
  border: 1px solid #eef1f6;

  &:hover {
    background-color: #f4f6f9;
    border: 1px solid #f4f6f9;
  }

  &:active {
    background-color: #eef1f6;
    border: 1px solid #eef1f6;
  }
}

.button_link {
  width: 100%;
  height: 100%;
  padding: 0 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    @include button-text;
  }
}
