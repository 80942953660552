@import "../../../styles/description.scss";

.container {
  overflow: hidden;
}

.paddingTop {
  padding-top: 60px;

  @include mobile {
    padding-top: 40px;
  }
}

.paddingBottom {
  padding-bottom: 60px;

  @include mobile {
    padding-bottom: 40px;
  }
}

.backgroundBg {
  background: $color-gray-bgc-2;
}

.backgroundBgLight {
  background: $color-light-disabled;
}

.backgroundWhite {
  background: $color-white;
}
