@import "../../../styles/description.scss";

.container {
  height: 400px;
  background-image: url("/images/404/404-banner.jpeg");
  background-size: contain;
  background-repeat: no-repeat;
  background-color: var(--bg);

  @include laptop {
    background-size: cover;
    background-position: right;
  }

  @include mobile {
    height: 619px;
    background-size: contain;
    background-image: url("/images/404/404-banner-mobile.png");
    background-position: top center;
  }

  .content {
    @include content;
    padding-top: 91px;

    @include mobile {
      padding-top: 331px;
    }

    .description {
      margin-top: 16px;
      width: 395px;

      @include mobile {
        width: 100%;
        margin-top: 12px;
      }
    }

    .button {
      width: 249px;
      margin-top: 35px;

      @include mobile {
        width: 100%;
      }
    }
  }
}