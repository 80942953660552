/** Default **/
.smartbanner {
  width: 100%;
  height: 80px;
  font-family: "Manrope", sans-serif;
  background: #f4f4f4;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  -webkit-text-size-adjust: none;

  &__container {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0 auto;
    white-space: nowrap;
  }

  &__close {
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px 0 5px;
    font-size: 20px;
    text-align: center;
    color: #888;
    text-decoration: none;
    border: 0;
    border-radius: 14px;
    -webkit-font-smoothing: subpixel-antialiased;

    &:active,
    &:hover {
      color: #aaa;
    }
  }

  &__icon {
    display: inline-block;
    vertical-align: middle;
    max-width: 57px;
    max-height: 57px;
    margin-right: 12px;
    background-size: cover;
    border-radius: 10px;
  }

  &__info {
    flex-grow: 1;
    display: inline-block;
    vertical-align: middle;
    width: 44%;
    font-size: 11px;
    line-height: 1.2em;
    font-weight: bold;
  }

  &__title {
    font-size: 13px;
    line-height: 18px;
  }

  &__button {
    margin: auto 20px auto 0;
    height: 24px;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    font-weight: bold;
    color: #6a6a6a;
    text-transform: uppercase;
    text-decoration: none;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);

    &:active,
    &:hover {
      color: #aaa;
    }
  }

  /** iOS **/
  &__ios {
    background: #f4f4f4;
    background: linear-gradient(to bottom, #f4f4f4, #cdcdcd);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);

    .smartbanner {
      &__close {
        border: 0;
        width: 18px;
        height: 18px;
        line-height: 18px;
        color: #888;
        text-shadow: 0 1px 0 white;

        &:active,
        &:hover {
          color: #aaa;
        }
      }

      &__icon {
        background: rgba(0, 0, 0, 0.6);
        background-size: cover;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
      }

      &__info {
        color: #6a6a6a;
        text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
      }

      &__title {
        color: #4d4d4d;
        font-weight: bold;
      }

      &__button {
        padding: 0 10px;
        color: #6a6a6a;
        background: #efefef;
        background: linear-gradient(to bottom, #efefef, #dcdcdc);
        border-radius: 3px;
        box-shadow: inset 0 0 0 1px #bfbfbf, 0 1px 0 rgba(255, 255, 255, 0.6), 0 2px 0 rgba(255, 255, 255, 0.7) inset;

        &:active,
        &:hover {
          background: #dcdcdc;
          background: linear-gradient(to bottom, #dcdcdc, #efefef);
        }
      }
    }
  }

  /** Android **/
  &__android {
    background: #3d3d3d
      url(data:image/gif;base64,R0lGODlhCAAIAIABAFVVVf///yH5BAEHAAEALAAAAAAIAAgAAAINRG4XudroGJBRsYcxKAA7);
    box-shadow: inset 0 4px 0 #88b131;
    line-height: 82px;

    .smartbanner {
      &__close {
        border: 0;
        width: 17px;
        height: 17px;
        line-height: 17px;
        margin-right: 7px;
        color: #b1b1b3;
        background: #1c1e21;
        text-shadow: 0 1px 1px #000;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.8) inset, 0 1px 1px rgba(255, 255, 255, 0.3);

        &:active,
        &:hover {
          color: #eee;
        }
      }

      &__icon {
        background-color: transparent;
        box-shadow: none;
      }

      &__info {
        color: #ccc;
        text-shadow: 0 1px 2px #000;
      }

      &__title {
        color: #fff;
        font-weight: bold;
      }

      &__button {
        color: #d1d1d1;
        padding: 0;
        background: none;
        border-radius: 0;
        box-shadow: 0 0 0 1px #333, 0 0 0 2px #dddcdc;

        &:active,
        &:hover {
          background: none;
        }

        &_text {
          text-align: center;
          display: block;
          padding: 0 10px;
          background: #42b6c9;
          background: linear-gradient(to bottom, #42b6c9, #39a9bb);
          text-transform: none;
          text-shadow: none;
          box-shadow: none;

          &:active,
          &:hover {
            background: #2ac7e1;
          }
        }
      }
    }
  }
}
