@import "../../styles/description.scss";

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .container {
    flex-grow: 1;
    display: flex;

    .headerWrapper {
      width: 100%;
      position: fixed;
      z-index: 100;

      header {
        position: relative;
      }
    }

    .content {
      margin-top: 60px;
      width: 100%;

      @include mobile {
        margin-top: 48px;
      }

      &.contentWithSmartAppBanner {
        @include mobile {
          margin-top: 128px;
        }
      }

      .backDrop {
        transition: opacity 200ms;
        opacity: 0;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 90;
        background-color: var(--main);
        width: 100vw;
        height: 100%;
        visibility: hidden;

        &.menuExpanded {
          transition: opacity 200ms;
          opacity: 30%;
          visibility: visible;
        }
      }
    }
  }
}
