@import "../../../styles/description.scss";

.title {
  margin: 0;
}

.h1 {
  @include h1;
}

.h2 {
  @include h2;
}

.h3 {
  @include h3;
}

.h4 {
  @include h4;
}

.h5 {
  @include h5;
}

.simpleText {
  @include simple-text;
}

.lidTitle {
  @include lid-title;
}

.buttonText {
  @include button-text;
}

.bigLink {
  @include big-link;
}

.ultraLittleText {
  @include ultra-little-text;
}

.inputText {
  @include input-text;
}

.errorText {
  @include error-text;
}

.littleButtonText {
  @include little-button-text;
}

.littleLink {
  @include little-link;
}

.littleText {
  @include little-text;
}

.inputTitle {
  @include input-title;
}

.numberText {
  @include number-text;
}

.semiboldSimpleText {
  @include semibold-simple-text;
}
